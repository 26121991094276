<template>
  <div :class="`${prefixCls}-video`" id="aochenVideo">
    <Separate title="视频中心" desc="VIDEO CENTER" class="wow animate__fadeInUp" data-wow-duration="1s"></Separate>
    <div :class="`${prefixCls}-video-body`">
      <custom-card class="card" v-for="item in videoList" :key="item.id" :value="item" @click="handleClick" />
    </div>
  </div>
</template>

<script>
import Separate from '../../../components/Separate/index'
import { WowMixin } from '@/mixin/wow.js'
import { sitespzxPage } from '@/api/aochen'
import CustomCard from '@/components/CustomCard/index.vue'
export default {
  name: 'AochenVideo',
  data () {
    return {
      prefixCls: this.$prefix + '-company',
      content: null,
      videoList: [
        {
          id: 1,
          title: '宣传视频',
          pic: 'http://back.hls-pharm.com/video/fm.png',
          video: 'http://back.hls-pharm.com/video/sp.mpg'
        }
      ]
    }
  },
  mixins: [WowMixin],
  components: {
    CustomCard,
    Separate
  },
  created () {
    this.sitespzxPage()
  },
  methods: {
    handleClick (data) {
      console.log(data, 'data')
      this.$router.push({ path: '/videoDetail', query: { url: data.video } })
    },
    sitespzxPage () {
      sitespzxPage().then(({ data }) => {
        console.log(data, 'data')
      })
    }
  }
}
</script>

<style lang="scss">
$prefixCls: $namespace + -company-video;
.#{$prefixCls}{
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  align-items: center;
  width: 800px;
  flex-grow: 1;
  &-body{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .card {
      width: 380px;
      margin: 0 25px 25px 0;
      img {
        object-fit: contain !important;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>
