import request from '../utils/axios/index'

export const BASE_URL = '/websites-server/rest/frontDesk'

// 获取产品展示数据
export function getAbout (data) {
  return request({
    method: 'post',
    url: `${BASE_URL}/getAbout`,
    data
  })
}

export function sitespzxPage () {
  return request({
    url: '/rest/sitespzx/getPage',
    method: 'post',
    data: { channelId: 0 }
  })
}
